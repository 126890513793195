import React from 'react'
import {graphql} from 'gatsby'
import Header from '../layouts/header'
import Footer from '../layouts/footer'
import Canvas from '../layouts/canvas'
import Breadcrumb from '../layouts/breadcrumb'
import {Helmet} from 'react-helmet'
import TagCloud from 'react-tag-cloud';
import randomColor from 'randomcolor';
import Img from 'gatsby-image'


class Seotemplate extends React.Component{
  constructor(props) {
    super(props)
  }

  componentDidMount(){
    this.interval2 = setInterval(() => {
     this.forceUpdate();
   }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.interval2)
  }

  render() {
    var colors=['#D15241','#CE412C','#FFC900', '#FCD462','#F6C358','#4BC1AF','#30968B','#252E33','#1C2022','#9A9A9A','#1F2732','#232425']
    var menu;
    if(this.props.pageContext.langcode === 'EN') {// Gia na perasei to analogo menu ston header
       menu = this.props.pageContext.menuen
    }
    else {
      menu = this.props.pageContext.menuel
    }
    var langpath =[]
    this.props.pageContext.langcontainer.forEach((item) => { // Gia na presasei ta url sto header
      langpath.push({url:item.entityUrl.path, id:item.entityLanguage.id})
    })

    var loc = []; //TODO pernaei san property sto breadcrumb, prepei na ginei function kai na to kano import giati einai idio se ola ta components
    var breadpath = this.props.location.pathname.split(/[/]/)
    breadpath.shift()
    breadpath.forEach((item,i) => {
      var value = null;
      var path = null;
      // console.log(item,i,loc[0])

      if(i === 0) {
        if(item === 'en' || item === 'el') {
          value = 'HOME';
          path=`/${item}`
        }
        else {
          value=item;
          path=`/${item}`
        }
        loc.push({value: value, path: path})
      }
      else {
        value=item;
        path=`${loc[i-1].path}/${item}`
        loc.push({value: value, path: path})
      }





    })
    return (
      <div>
        <Helmet>
         {this.props.pageContext.metainfo.map((item,i) => {
           if(item.key === 'title') {
             return (
                [<title key={i}>{item.value}</title>,<meta property="og:title" content={item.value} />]
             )
           }
  

           else if(item.key === 'description') {
             return (
               [ <meta key={i} name="description" content={item.value}/>, <meta property="og:description" content={item.value} />]
             )
           }

           else {
             return null;
           }
         })}
         <link  rel="canonical" href={process.env.FRONT_URL+this.props.path+'/'} />
         <meta property="og:type" content="Website" />
         <meta property="og:url" content={`${process.env.FRONT_URL}${this.props.path}`} />
         <meta property="og:image" content={`${process.env.FRONT_URL}${this.props.data.drupal.nodeById.fieldDesktopImage[0].imageFile.childImageSharp.fluid.src}`} />
         <meta property="og:image:width" content="400" />
         <meta property="og:image:height" content="300" />
        </Helmet>
        <Canvas>
     <Header language={this.props.pageContext.langcode} menu={menu} langpath={langpath}/>
       <Breadcrumb title={this.props.data.drupal.nodeById.title} path={loc}/>
       <div className="container-fluid containerworks mt-5">
           <div className="row">
              <div className="col-md-8 mb-5">
                  <div className="imagecont imagecontdesktop">
                    <div className="desktopscreen">
                      <div className="firstdiv">
                         <img src="/SIMA1.png" alt=""/>
                      </div>
                    <div className="imagecontinnerdesk">
                   <Img fluid={this.props.data.drupal.nodeById.fieldDesktopImage[0].imageFile.childImageSharp.fluid} alt={this.props.data.drupal.nodeById.fieldDesktopImage[0].alt} />
                     </div>
                     </div>

                         <i className="imagescrollcont fas fa-angle-double-down animated fadeIn floating"></i>





                  </div>


              </div>
              <div className="col-md-4">

                  <div className="tagheader">
                    <h6>{this.props.pageContext.langcode === 'EL'? "ΑΝΑΖΗΤΗΣΕΙΣ ΜΕ ΠΡΩΤΕΣ ΘΕΣΕΙΣ ΣΤΗΝ GOOGLE" : "FIRST ON GOOGLE PAGE"}</h6>
                  </div>

                   <TagCloud className='tag-cloud' style={{
          fontFamily: 'sans-serif',
          fontSize: () => Math.round(Math.random() * 15) + 10,

          fontStyle: 'italic',

          padding:5,
          width: '100%',
          height: '400px'


        }}>
        {this.props.data.drupal.nodeById.fieldKey.map((item,i) => {

          return (
            <div  style={{color: colors[Math.floor(Math.random() * colors.length)]}} className="clouditem">{item}</div>
          )
        })}

                   </TagCloud>


              </div>




           </div>
           <div className="contentcontainer mt-5 row">
                <div className="col-12 text-center">
             {this.props.data.drupal.nodeById.body ? <div dangerouslySetInnerHTML={{ __html: this.props.data.drupal.nodeById.body.processed }} /> : null}
                   </div>
           </div>
       </div>

       <Footer/>

     </Canvas>
     </div>
    )
  }


}
export default Seotemplate

export const query = graphql`
query($slug: String!, $langcode: DRUPAL_LanguageId!)  {
  drupal {
     nodeById(id: $slug, language: $langcode) {
       entityId
       title
       ... on DRUPAL_NodePortfolio {
         fieldKey
         body {
           processed
         }
         fieldDesktopImage {
          url
          alt
          imageFile {
             childImageSharp {
               fluid(maxWidth: 1300, quality: 90, fit: COVER) {
                 ...GatsbyImageSharpFluid
               }
             }
           }
        }

       }
     }
   }
}
`
